@import '~src/css/vars.scss';

.dateformContainer {
  margin: 20px 0px;

  h2 {
    margin-bottom: 0;
    margin-top: 40px;
  }

  p {
    margin-top: 10px;
    padding-bottom: 0;
  }

  &-error {
    color: $red;
  }
}

.required {
  color: $red;
}

.dateform {
  display: flex;
  flex-direction: row;
  height: 110px;
  overflow-x: scroll;
  padding-left: 8px;
  padding-top: 8px;
  scroll-snap-type: x mandatory;
}

.errors {
  background-color: $red-error;

  border-radius: 25px;
  color: white;
  margin-top: 20px;
  max-width: 50%;
  padding: 1rem 1.5rem;
  position: relative;
  width: fit-content;

  a {
    color: white;
    text-decoration: dotted underline;
  }

  :after {
    content: '';
    height: 10px;
    left: 35px;
    position: absolute;
    top: -5px;
    transform: rotate(-45deg);
    width: 10px;
    z-index: -10;
  }
}
