@import '~src/css/vars.scss';

.loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    width: 50% !important;

    div > input {
        border: 1px solid $secondary-green-900;
        border-radius: 3rem;
        width: calc(100% - 40px);
    }

    :first-child {
        margin-bottom: 25px;
    }

    @media (max-width: $breakpoint-mobile) {
        width: 75% !important;
    }
}
