@import '~src/css/vars.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.extraCopy {
  font-weight: 400;
  margin-bottom: 2.5em;
  text-align: center;
}

.title {
  margin-bottom: 1em;
  text-align: center;
}

.shield {
  height: 3em;
  width: auto;

  path {
    stroke: $primary-dark-green;
  }
}
