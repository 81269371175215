@import '~src/css/vars.scss';

.partsAddedContainer {
    background-color: $carbon14;
    border-radius: 25px 25px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0.5em;

    > div:not(:first-child) {
        border-top: 1px solid $gray-light;
    }
}

.partsSummaryContainer {
    width: 100%;
    background-color: $yellow;
    margin-bottom: 2em;
    border-radius: 0 0 25px 25px;
    display: flex;
    justify-content: center;
}

.partsGrid {
    margin: 1em;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1px 1fr;
}

.partsGridLabel {
    justify-self: right;
    align-self: center;
    margin: 0.5em 0;
}

.partsGridValue {
    justify-self: left;
    align-self: center;
    margin: 0.5em;

    span {
        font-size: 24px;
        text-decoration-line: underline;
    }
}
.line {
    background-color: $black;
    grid-column: 1 / -1;
}
