.carouselContainer {
    width: 100%;
    overflow-x: scroll;
    height: 205px;
    overflow-y: hidden;

    &::-webkit-scrollbar {
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        border-color: white;
        box-shadow: inset 0 0 2px white;
        width: 40px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: white;
        width: 40px;
      }
}

.carouselContent {
    display: flex;
    transition: all 250ms linear;
}

.carouselContent > * {
    flex-shrink: 0;
    flex-grow: 1;
}

@media (max-width: 400px) {
  .carouselContent > * {
    width: 85%;
}
}