@import '~src/css/vars.scss';

.textArea,
input.textAreaSingle {
    border: 2px solid $gray-light;
    margin-bottom: 14px;
}

.button {
    width: 100%;
}

.title {
    font-size: 22px;
    padding: 28px 1.5em;
    text-align: center;
    font-size: 1.5rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.inputAndError {
    width: 100%;
}

.errorBorder {
    color: $red-error;
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;
}

.formControl {
    margin-bottom: 1.5em;

    label {
        font-size: 1em;
        text-align: center;

        span {
            @include h3;
            display: block;
            margin-bottom: 0.5em;
            min-width: 30%;
        }

        select {
            line-height: 1.2;
        }

        input {
            width: stretch;
        }
    }
}
