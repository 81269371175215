@import '~src/css/vars.scss';

.themeGrey {
  background: $secondary-green-100;
  color: $primary-mid-green-2;
}

.themePurple {
  background: $secondary-orange-100;
  color: $primary-mid-green-2;
}

.themeGrey, .themePurple {
  div[data-component="Header"] {
    svg path {
      stroke: $primary-mid-green-2;
    }
  }
  

  span {
    color: $primary-mid-green-2 !important;
  }

  button {
    background: $primary-mid-green-2;

    span {
      color: $primary-yellow !important;
      font-weight: 500;
    }

    svg path {
      stroke: $primary-yellow;
    }

    &:hover {
      span {
        color: $primary-mid-green-2 !important;
      }

      svg path {
        stroke: $primary-mid-green-2;
      }
    }
  }
}

.expando {
  overflow: hidden;
  padding-bottom: 1rem;

  .glance {
    // background-color: $primary-mid-green-2;
    // color: $white;
    min-height: 40px;
    padding: 0.5em 1.2em;
    position: relative;
    user-select: none;
    z-index: 0;

    .header {
      align-items: center;
      border-bottom: 1px solid $carbon6;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .arrow {
        height: 40px;
        width: 40px;
      }
    }

  }

  .details {
    background: $white;
    display: block;
    position: relative;
    width: 100%;
  }

  @media (min-width: $ht-lrg) {
    .info {
      grid-template-columns: 3fr 3fr 1fr 3fr;
    }
  }
}
