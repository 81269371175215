@import '~src/css/vars.scss';

.main {
    position: relative;
    width: 100%;
    align-self: center;
    min-height: 50px;

    .dropdownContainer {
        font-size: 1.3rem;
        border: 1px solid $primary-dark-green;
        border-radius: 25px;
        position: absolute;
        width: 100%;
        background-color: #fff;
        z-index: 1;
        overflow: hidden;

        .dropdownContent {
            position: relative;
            width: 100%;
            padding: 0;
            border-radius: 25px;
            overflow: hidden;
        }

        .button {
            border: 0;
            background: inherit;
            margin: 0;
            padding: 0;
            border-radius: 25px;
            width: 100%;
            cursor: pointer;

            .dropdownFirstItem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 25px;
            }

            .icon {
                align-self: center;
                display: flex;
                align-items: center;

                svg {
                    rect {
                        fill: none;
                    }
                }
            }
        }

        .searchInput {
            outline: none;
            padding: 0;
            height: 48px;

            &.edited {
                color: $primary-dark-green;
            }
        }

        .dropdownList {
            z-index: 100;
            max-height: 300px;
            overflow-y: auto;
            border-radius: 0;
            padding: 0;

            .listItem {
                list-style: none;
                margin: 0;

                &:before {
                    content: none;
                }
            }

            .listItemButton {
                display: flex;
                justify-content: space-between;
                padding: 10px 15px;
                width: 100%;
                background-color: inherit;
                border: 0;
                border-radius: 0;
                text-align: left;
                cursor: pointer;

                &:hover {
                    background-color: $primary-highlight-green;
                }
            }

            .listItemIcon {
                padding-top: 3px;
                display: flex;
                align-items: center;
            }

            .selected {
                background-color: $primary-yellow;
                font-weight: 600;
            }
        }

        .dropdownContent,
        .dropdownList {
            transition: all 0.3s ease;
            border-radius: 0;
        }
    }
}
