@import 'src/css/vars.scss';

.notImplementedPage {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5em 1em 0 1em;

  a {
    color: $gray-light;
  }

  img {
    height: 40px;
  }

  h1 {
    font-size: 22px;
    line-height: 27px;
    font-weight: 600;
    letter-spacing: -0.5px;
  }

  p {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: -0.2px;
    text-align: center;
  }
}
