@import '~src/css/vars.scss';

.backButton {
    background: transparent;
    transform: rotate(-90deg);
    svg {
        path {
            stroke: $primary-dark-green;
        }
    }
}

.jobDetailsContainer {
    overflow: hidden;
    padding-bottom: 1rem;

    .glance {
        background: $primary-mid-green-2;
        color: $white;
        min-height: 40px;
        padding: 1.5em 1.5em 0;
        position: relative;
        user-select: none;
        z-index: 0;

        .header {
            border-bottom: 1px solid $white;
            padding-bottom: 1.2em;
            width: 100%;

            .arrow {
                height: 40px;
                width: 40px;
            }

            > div {
                width: 100%;

                div:last-of-type {
                    justify-self: end;
                    margin-right: 0;
                }
            }
        }

        .bottomTriangle {
            background-color: $primary-mid-green-2;
            bottom: -13px;
            display: block;
            height: 26px;
            left: 50px;
            position: absolute;
            transform: rotate(45deg);
            width: 26px;
            z-index: 3;
        }
    }

    .details {
        background: $white;
        display: block;
        position: relative;
        width: 100%;
        svg {
            path {
                stroke: $primary-mid-green-2;
            }
        }
    }

    @media (min-width: $ht-lrg) {
        .info {
            grid-template-columns: 3fr 3fr 1fr 3fr;
        }
    }
}
