@import 'src/css/vars.scss';

.extraCopy {
    color: $carbon4;
    font-weight: 400;
    margin-bottom: 2.5em;
    margin-top: 1.4em;
    text-align: center;
}

.title {
    padding-bottom: 14px;
    text-align: center;
}

.icon {
    height: 3em;
    padding: 28px 0 14px;
    width: auto;

    path {
        fill: $primary-dark-green;
    }
}

.button {
    width: 100%;
}

.date {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    grid-column-end: 4;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1;
    justify-content: space-between;
    padding-bottom: 10px;

    .year {
        color: $carbon;
    }
}

.followOnDetails {
    background: $primary-dark-green;
    border-radius: 10px;
    color: $white;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    max-width: 400px;
    width: 100%;

    > div {
        padding: 1.125rem 2rem 1.5rem;

        > div:first-of-type {
            font-size: 1em;
            margin-bottom: 1rem;

            > span {
                color: $carbon7;
            }
        }

        > div:last-of-type {
            display: flex;
            font-weight: 500;
            gap: 2.25rem;
        }
    }

    .calendar {
        grid-column-end: 2;
        grid-column-start: 1;
        grid-row-end: 5;
        grid-row-start: 2;
        z-index: 3;

        .container {
            border-radius: 5px 5px 26px 5px;
            display: block;
            flex: 0 0 80px;
            margin-right: 10px;
            min-height: 92px;
            scroll-snap-align: center;
        }
    }

    .timeOfDay {
        align-items: center;
        display: flex;
        font-size: 20px;
        grid-column-end: 4;
        grid-column-start: 2;
        grid-row-end: 3;
        grid-row-start: 2;
        transform: translateX(-32px);

        &.Morning,
        &.Evening {
            &::before {
                background: $yellow;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                content: '';
                display: block;
                height: 10px;
                margin-right: 12px;
                width: 20px;
            }
        }

        &.Afternoon {
            &::before {
                background: $yellow;
                border-radius: 20px;
                content: '';
                display: block;
                height: 20px;
                margin-right: 12px;
                width: 20px;
            }
        }
    }

    .timeSlot {
        font-size: 20px;
        grid-column-end: 4;
        grid-column-start: 2;
        grid-row-end: 4;
        grid-row-start: 3;
        padding: 10px 0;
    }

    .bottomTriangle {
        background: $carbon4;
        bottom: 10px;
        display: block;
        height: 26px;
        left: 50px;
        position: absolute;
        transform: rotate(45deg);
        width: 26px;
        z-index: 2;
    }
}
