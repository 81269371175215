.container {
    box-shadow: 0px 7px 7px 1px #888888;
    padding: 10px;
    margin-top: 20px;
    font-weight: 400;
    border-radius: 20px;

    .heading {
        margin: 0;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }

    .closeButton {
        background-color: inherit;
        padding: 0;
        line-height: 0;
        margin-left: 5px;
        float: right;
    }
}
