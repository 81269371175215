@import '~src/css/vars.scss';

.textArea,
input.textAreaSingle {
    border: 2px solid $gray-light;
}

.button {
    margin-top: 14px;
    width: 100%;
}

.title {
    padding: 28px 1em;
    text-align: center;
    font-size: 1.5rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.inputAndError {
    width: 100%;
}

.errorBorder {
    border-color: $red-error;
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;
}

.extraCopy {
    font-weight: 400;
    margin-bottom: 1em;
    text-align: center;
}
