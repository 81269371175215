@import '~src/css/vars.scss';

.title {
    @include h4;
    padding: 28px 1.5em;
    text-align: center;
    font-size: 1.5rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.addPart {
    background: inherit;
    border: 2px solid $carbon5;
    display: flex;
    flex-direction: row;
    font-size: 2em;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 20px;
    width: 100%;
    p {
        margin: 10px;
        padding: 0;
        text-align: center;
        :last-of-type {
            font-size: 2em;
        }
    }
}

.hoursNeededLabel {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    span {
        margin-right: 2em;
    }

    select {
        padding: 0 2em;
        width: 50%;

        &:focus {
            border-color: $yellow-alt;
        }
    }
}

.mainButton {
    margin-top: 30px;
    width: 100%;
}
