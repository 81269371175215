@import '~src/css/vars.scss';

.textArea {
  border: 0;
  border: 2px solid $gray-light;
  border-radius: 10px;
  color: $carbon;
  font-family: $font-body;
  font-size: 16px;
  height: 50px;
  line-height: 20px;
  padding: 20px;
  width: stretch;
}
