@import '~src/css/vars.scss';

.jobOffers {
  margin: 3rem 0;

  .jobOffersList {
    .jobOfferRow {
      background-color: $carbon4;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75);
      margin-bottom: 1.25rem;
      padding: 1rem;
    }
  }

  .jobOffersTitle {
    font-size: 1.3em;
    font-weight: 600;
    margin: 0 0 3rem 0;
    padding: 0;
    text-align: center;
  }

  .middleRow {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}

.offerAccepted {
  background-color: $hometree-gold;
  border-radius: 10px;
  margin: 1.25em;

  button {
    background: none;
    border: none;
    display: block;
    margin-top: 0.5em;
    text-align: right;
    width: 100%;
    svg {
      stroke: white;
    }
  }

  p {
    margin: 0;
    padding: 0.5em;
    text-align: center;
  }
  p:first-of-type {
    color: $carbon1;
    font-size: 1.5em;
    font-weight: 600;
  }
  p:last-of-type {
    color: white;
    font-size: 1.3em;
    margin-bottom: 1em;
  }
}
.otherJobOffersTitle {
  color: $carbon10;
  margin: 0px;
  margin: 2.56rem 0rem 0.69rem;
  padding-bottom: 0px;
  text-align: center;
}
.tag {
  align-items: center;
  display: flex;
  margin-bottom: 0.5rem;

  span:first-of-type {
    display: flex;
    height: 22px;
    width: 22px;

    img {
      width: 100%;
    }
  }

  span:last-of-type {
    padding: 0.1rem 0.4rem;
  }
}
