@import '~src/css/vars.scss';

.textArea,
input.textAreaSingle {
    border: 2px solid $gray-light;
    margin-bottom: 14px;
}

.button {
    width: 100%;
}

.title {
    padding: 28px 1.5em;
    text-align: center;
    font-size: 1.5rem;
    line-height: 100%;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.subText {
    font-weight: 500;
    margin-bottom: 0.8em;
    margin-top: 0.5em;
}

.inputAndError {
    width: 100%;
}

.errorBorder {
    border-color: $red-error;
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;

    a {
        color: inherit;
        font-weight: bold;
    }
}

.formControl {
    margin-bottom: 1em;

    label {
        align-items: center;
        display: flex;
        font-size: 1rem;

        span {
            display: block;
            min-width: 50%;
        }

        select {
            line-height: 1.2;
        }
    }
}

.costText {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-bottom: 20px;

    span {
        &:first-of-type {
            color: $carbon8;
            font-weight: 600;
            min-width: 50%;
        }

        &:last-of-type {
            padding: 0 20px;
        }
    }
}

.confirmationText {
    margin-bottom: 0;
    margin-top: 3rem;
    padding-bottom: 0;
    text-align: center;

    a {
        color: $carbon1;
    }
}
