@import '~src/css/vars.scss';

.jobAgenda {
    h3 {
        margin-bottom: 1em;
        margin-top: 1em;
        padding: 0 1.5rem;
        @include h4;
    }

    .spinnerContainer {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
    }

    .customSpinner div:after {
        background-color: #ffffff;
    }

    .noMoreJobs {
        align-items: center;
        color: $carbon8;
        display: flex;
        height: 100%;
        justify-content: center;
    }
}
