@import '../../css/vars.scss';

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: calc(50% - 22px);
}

.dot {
  background: $black;
  border-radius: 100%;
  color: $white;
  height: 8px;
  line-height: 8px;
  text-align: center;
  width: 8px;
}

.dotOne {
  animation: dotOneKeyframes 5s ease infinite;
  background: $yellow;
  position: absolute;
  top: 0;
  left: 0;
  border: 4px solid $yellow;
}

.dotTwo {
  animation: dotTwoKeyframes 5s ease 0.4166666666666s infinite;
  background: $yellow;
  position: absolute;
  top: 0;
  left: 22px;
  border: 4px solid $yellow;
}

.dotThree {
  animation: dotThreeKeyframes 5s ease 0.83333333333s infinite;
  background: $yellow;
  position: absolute;
  top: 22px;
  left: 22px;
  border: 4px solid $yellow;
}

@keyframes dotOneKeyframes {
  0% {
    top: 0;
    left: 0;
  }
  8.3333333333% {
    top: 22px;
    left: 0;
  }
  16.6666666666% {
  }
  25% {
    top: 22px;
    left: 0;
  }
  33.3333333333% {
    top: 22px;
    left: 22px;
  }
  41.6666666666% {
  }
  50% {
    top: 22px;
    left: 22px;
  }
  58.3333333333% {
    top: 0;
    left: 22px;
  }
  66.6666666666% {
  }
  75% {
    top: 0;
    left: 22px;
  }
  83.3333333333% {
    top: 0;
    left: 0;
  }
  91.6666666666% {
  }
  100% {
  }
}

@keyframes dotTwoKeyframes {
  0% {
    top: 0;
    left: 22px;
  }
  8.3333333333% {
    top: 0;
    left: 0;
  }
  16.6666666666% {
  }
  25% {
    top: 0;
    left: 0;
  }
  33.3333333333% {
    top: 22px;
    left: 0;
  }
  41.6666666666% {
  }
  50% {
    top: 22px;
    left: 0;
  }
  58.3333333333% {
    top: 22px;
    left: 22px;
  }
  66.6666666666% {
  }
  75% {
    top: 22px;
    left: 22px;
  }
  83.3333333333% {
    top: 0;
    left: 22px;
  }
  91.6666666666% {
  }
  100% {
  }
}

@keyframes dotThreeKeyframes {
  0% {
    top: 22px;
    left: 22px;
  }
  8.3333333333% {
    top: 0;
    left: 22px;
  }
  16.6666666666% {
  }
  25% {
    top: 0;
    left: 22px;
  }
  33.3333333333% {
    top: 0;
    left: 0;
  }
  41.6666666666% {
  }
  50% {
    top: 0;
    left: 0;
  }
  58.3333333333% {
    top: 22px;
    left: 0;
  }
  66.6666666666% {
  }
  75% {
    top: 22px;
    left: 0;
  }
  83.3333333333% {
    top: 22px;
    left: 22px;
  }
  91.6666666666% {
  }
  100% {
  }
}
