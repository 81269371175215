@import '~src/css/vars.scss';

.container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    width: 100%;

    &.NextStep {
        align-items: center;
        flex-direction: column;
        gap: 10px;

        button {
            display: block;
            line-height: 20px;
            padding: 10px 50px;
            text-align: center;
            width: 100%;
        }
    }

    &.CanLowerMyLabourCosts {
        align-items: center;
        flex-direction: column;
        gap: 0;

        button:last-child {
            background-color: transparent;
            border-radius: 0;
            height: 60px;
            text-decoration: underline;
        }
    }
}

.attention {
    margin-top: 12px;

    path {
        stroke: $primary-dark-green;
    }
}

.title {
    padding: 28px 1.5em;
    text-align: center;
    font-size: 1.5rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.body {
    margin: 28px 0;
    text-align: center;
}
