@import '~src/css/vars.scss';

.textareaFormControl {
    display: flex;
    flex-direction: column;

    .title {
        margin: 1.5em 0 0.25em;
        text-align: left;
        font-size: 1.5rem !important;
        @include h4;
    }

    .helper {
        color: $carbon3;
        font-size: 14px;
        padding: 0 0 0.25em;
        text-align: left;
    }

    .textarea {
        border: 1px solid $carbon11;
        border-radius: 10px;
        box-sizing: border-box;
        font-size: 16px;
        height: 145px;
        margin: 0.25em 0 1em;
        padding: 20px;
        resize: none;
        width: 100%;
    }

    .fnolError {
        margin-top: 0;
    }
}
