$black: #1f2328;
$white: #fff;
$yellow: #ffd600;
$yellow-alt: #ffc700;
$grey: #b2b6bb;
$red: #e23b16;
$red-error: #d20a0a;
$purple-light: #cc99ff;
$purple: #ae04fe;
$purple-medium: #8d53e9;
$purple-dark: #4f4b73;
$purple-transparent: #281f39;
$green: #83b002;
$orange: #ff7200;
$blue: #005fcc;
$hometree-gold: #cb9b4f;

// grays
$gray-light: #d8dbdd;
$gray: #c5c8cc;
$gray-dark: #9fa4aa;
$carbon: #788088;
$carbon1: #17171c;
$carbon3: #24313b;
$carbon4: #3f4955;
$carbon5: #424c58;
$carbon6: #656d77;
$carbon7: #788088;
$carbon8: #8b9299;
$carbon9: #9fa4aa;
$carbon10: #b2b6bb;
$carbon11: #c5c8cc;
$carbon12: #d8dbdd;
$carbon13: #ecedee;
$carbon14: #fafafa;

// sizes
$header-height: 83px;
$text-padding: 51px;
$navbar_padding: 0.6rem;
$navbar_height: 3.6rem;

// breakpoints
$ht-lrg: 1200px;

////////// REBRAND //////////

// Colours
$primary-yellow: #ffe53e;
$primary-highlight-green: #abde19;
$primary-mid-green-1: #83ac0c;
$primary-mid-green-2: #023e09;
$primary-dark-green: #172b02;
$primary-off-white: #fafbef;

$secondary-purple-100: #e7dcfd;
$secondary-purple-300: #a37fed;
$secondary-purple-500: #4f1fb0;
$secondary-purple-900: #160043;

$secondary-orange-100: #ffd6b0;
$secondary-orange-300: #ffa63d;
$secondary-orange-500: #ff8a00;
$secondary-orange-900: #5e340c;

$secondary-turquoise-100: #c4f8ff;
$secondary-turquoise-300: #3cd4e9;
$secondary-turquoise-500: #00b1d8;
$secondary-turquoise-900: #003137;

$secondary-green-100: #d4ffba;
$secondary-green-300: #44c991;
$secondary-green-500: #008e75;
$secondary-green-900: #024048;

$secondary-grey-100: #f5f3f3;
$secondary-grey-200: #d9d9d9;
$secondary-grey-300: #a5a3a3;
$secondary-grey-500: #807e7e;
$secondary-grey-900: #3e3d3d;
$secondary-black: #020202;
$secondary-grey-opacity-1: #172b0299;

$error: #dc1515;

// Fonts
$font-heading: 'elza-condensed', sans-serif;
$font-body: 'Instrument Sans', sans-serif;

// Base
$base-font-size: 16px;
$base-font-weight: 600;

// Breakpoints
$breakpoint-mobile: 768px;
$breakpoint-desktop: 1200px;

// Layout
$layout-main-width: 800px;
$layout-main-wide-width: 1386px;

@mixin h1 {
    font-family: $font-heading;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.08rem;
    line-height: 95%;

    @media (min-width: $breakpoint-desktop) {
        font-size: 8rem;
        letter-spacing: -0.16rem;
        line-height: 120%;
    }
}

@mixin h2 {
    font-family: $font-heading;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.024rem;
    line-height: 120%;

    @media (min-width: $breakpoint-desktop) {
        font-size: 4rem;
        letter-spacing: 0.04rem;
    }
}

@mixin h3 {
    font-family: $font-heading;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.02rem;

    @media (min-width: $breakpoint-desktop) {
        font-size: 2.4rem;
        letter-spacing: -0.024rem;
    }
}

@mixin h4 {
    font-family: $font-heading;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.02rem;

    @media (min-width: $breakpoint-desktop) {
        font-size: 2rem;
        letter-spacing: -0.02rem;
    }
}

@mixin bodytext {
    font-family: $font-body;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.056rem;
    line-height: 120%;

    @media (min-width: $breakpoint-desktop) {
        font-size: 1rem;
        letter-spacing: -0.064rem;
    }
}

@mixin normal_border {
    border: solid 3px rgba($primary-dark-green, 0.1);
}

// opacities
$carbon1_opacity3: rgba($carbon1, 0.3);
$dark_green_opacity1: rgba($primary-dark-green, 0.6);
