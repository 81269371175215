@import '~src/css/vars.scss';

.container {
    background-color: #fff;
    color: $carbon1;
    padding: 1.25em;

    .customerDetails {
        background-color: $carbon13;
        border-radius: 5px;
        padding: 1.625em 1.25em;
        margin-bottom: 2em;

        h3 {
            margin-top: 0;
            margin-bottom: 1em;
            color: $carbon4;
            text-transform: uppercase;
            font-size: 1.125em;
        }

        p {
            padding: 0;
            margin: 0 0 1em;
        }

        a {
            display: block;
            margin-top: 1.5em;
            color: $purple-medium;
            text-decoration: none;
            display: flex;
            gap: 0.5em;
        }
    }

    .rescheduleReasonDropdown {
        label {
            font-size: 1.125em;
            font-weight: 600;
            margin-bottom: 1em;
            display: block;
        }

        select {
            line-height: 1.2;
        }
    }

    h4 {
        font-size: 1.125em;
        font-weight: 600;
        margin-top: 2em;
        margin-bottom: 0;
    }

    .dateFormWrapper {
        h2 {
            margin-top: 1em;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3em;

        button {
            width: 350px;
            max-width: 100%;
            justify-content: center;
        }
    }

    .otherReason {
        margin-top: 1.25em;

        .textArea {
            border: 2px solid $gray-light;
            margin-bottom: 14px;
        }
    }
}

.checkbox {
    margin-right: 0.5rem;
}
