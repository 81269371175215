@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.main {
    display: flex;
    flex-direction: column;
    padding-bottom: calc(#{$navbar_height} + 2 * #{$navbar_padding});
}

.purple-bg {
    @include background-opacity($purple-medium, 0.2);
}

@media (min-width: 1200px) {
    .main {
        padding-left: calc((100% - $layout-main-width) / 2);
        padding-right: calc((100% - $layout-main-width) / 2);

        &[data-mode='wide'] {
            padding-left: calc((100% - $layout-main-wide-width) / 2);
            padding-right: calc((100% - $layout-main-wide-width) / 2);
        }
    }
}
