@import '~src/css/vars.scss';

input[type='text'].input {
    border: 2px solid $gray-light;
    border-radius: 3em;
    color: $carbon;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    padding: 0 20px;
    line-height: 20px;

    &:disabled {
        cursor: not-allowed;
    }
}
