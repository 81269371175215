@import 'src/css/vars.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark_green_opacity1;
}

.cancelBtn {
    align-self: flex-end;
    background-color: transparent;
    padding: 0;
    line-height: 20px;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus {
        background: none;
        background-color: none;
    }
}

.modalContainer {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    button {
        width: fit-content;
    }
}
