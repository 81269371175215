@import '~src/css/vars.scss';

.cardWrapper {
    text-decoration: none;

    &:link {
        color: $primary-dark-green;
    }

    .offerCardContainer {
        background-color: $white;
        padding: 18px 30px;
    }

    .postcodeDisplay {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        p {
            margin: 0;
            padding: 0;
            span:first-of-type {
                color: $primary-dark-green;
            }
        }

        a {
            background-color: none;
            border: 0;
            border-radius: 50%;
            height: 30px;
            padding: -9px;
            padding-left: 0px;
            width: 30px;
            svg {
                height: 30px;
                transform: rotate(90deg);
                width: 33px;
                path {
                    stroke: $primary-dark-green;
                    stroke-width: 3;
                }
            }
        }
    }
    .summary {
        font-weight: 500;
        margin: 20px 0;
        padding: 0;
    }
    .urgencyText {
        display: inline-block;
        font-weight: 500;
        margin-left: 5px;
        text-transform: uppercase;
        vertical-align: super;
    }
}
