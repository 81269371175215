@import '~src/css/vars.scss';

.button {
    width: 100%;
}

.select {
    background: $white;
    border: 2px solid $gray-light;
    border-radius: 2em;
}

.dropdown {
    padding: 0 1em;
}

.title {
    @include h4;
    padding: 28px 1.5em;
    text-align: center;
    font-size: 1.5rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;

    p {
        margin: 0;
        margin-top: 0.5em;
        padding-bottom: 0px;
    }

    a {
        color: inherit;
        font-weight: bold;
    }
}

.confirmationText {
    margin-top: 1rem;
    margin-top: 3rem;
}
