@import '~src/css/vars.scss';

.title {
    @include h4;
    margin: 0.5em 0 0.5em;
    padding: 0;
    text-align: left;
}

.description {
    margin: 0 0 0.5em;
    padding: 0;
    text-align: left;
}

.button {
    width: 100%;
}

.categoryBtns {
    display: flex;
    padding: 1rem 0;
    gap: 15px;
    overflow-x: scroll;

    & > .selected {
        color: $secondary-green-100;
        background: $primary-dark-green;
    }

    .capitalised {
        text-transform: capitalize;
        min-width: 200px;
        justify-content: center;
    }
}

.descriptionContainer {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;

    .descriptionInput {
        margin: 20px 0;
        border: 1px solid $primary-dark-green;
        height: auto;
        border-radius: 20px;
        padding: 20px;
    }
}

.inputAndError {
    display: flex;
    gap: 33px;
    margin-top: 5px;
    overflow-x: scroll;

    .radioContainer {
        justify-items: center;
        display: flex;
        gap: 33px;
        min-height: 200px;

        .radioOption {
            margin-bottom: 0.75em;
            padding-bottom: 0.25em;
            width: 100px;

            .optionName {
                padding: 10px 0;
                font-weight: 600;
                text-align: center;
            }
        }
    }
}
