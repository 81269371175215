@import '~src/css/vars.scss';

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .details {
        align-items: center;
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        justify-items: start;
        margin-bottom: 1em;
        width: calc(100% - 40px);

        .title {
            color: $carbon10;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            padding: 0.625em 0 0.5em;
        }

        .subDetails {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .time {
            color: $white;
            font-size: 20px;
            font-weight: 500;
            line-height: 27px;
        }

        .status {
            align-items: center;
            display: flex;
            flex-direction: row;

            svg {
                height: 20px;
                width: 20px;
            }

            span {
                margin-left: 0.5em;
            }
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        justify-content: flex-end;
    }
}
