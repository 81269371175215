@import 'src/css/vars.scss';

.container {
    padding-left: 1.5em;
    padding-right: 1.5em;

    @media (min-width: $ht-lrg) {
        padding-left: 2.5em;
        padding-right: 2.5em;
    }
}

.table-container {
    display: grid;
    margin-bottom: 1rem;

    .subTitle {
        margin: 0.5rem 0;
    }

    p {
        padding-bottom: 0;
    }

    h3,
    h4 {
        font-size: 1.5rem;
    }

    @media (min-width: $ht-lrg) {
        gap: 1rem 3rem;
        grid-template-columns: 1fr 1fr 3fr;
    }
}

.header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.cancelBtn {
    justify-content: center;
    margin-top: 0;
}
