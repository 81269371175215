@import 'src/css/vars.scss';

.failedContainer {
    align-content: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 400px;
    text-align: center;

    h4 {
        text-align: center;
    }

    .extraCopy {
        color: $carbon4;
        font-weight: 400;
        margin: 0.5em 0 1.5em;
        text-align: center;
    }

    .icon {
        height: 3em;
        padding: 28px 0 14px;
        width: auto;

        path,
        circle {
            stroke: $primary-dark-green;
        }
    }

    ul {
        margin-bottom: 1em;

        li {
            margin: 0;
        }
    }

    p {
        margin-bottom: 1em;
    }
}
