@import '~src/css/vars.scss';
@import '~src/css/fonts.scss';

.button {
  align-items: center;
  background-color: $secondary-grey-200;
  border: 0;
  border-radius: 22px;
  color: $primary-dark-green;
  cursor: pointer;
  display: flex;
  font-family: $font-body;
  font-size: 14px;
  font-weight: 500;
  height: 44px;
  justify-content: center;
  letter-spacing: -0.28px;
  line-height: 15px;
  padding: 9px 40px;
  position: relative;
  transition: all 0.3s;

  > .spinner {
    margin-bottom: 10px;
    margin-left: 10px;
  }

  &-primary {
    background-color: $primary-yellow;

    &:hover,
    &:focus {
      background-color: $primary-highlight-green;
    }
  }

  &-secondary {
    background: transparent;
    border: 1px solid $primary-dark-green;

    &:hover,
    &:focus {
      background-color: $primary-highlight-green;
    }
  }

  &-tertiary {
    background: $secondary-turquoise-100;
    color: $purple;

    &:hover,
    &:focus {
      background-color: $primary-highlight-green;
    }
  }

  &-logout {
    background-color: rgba($primary-dark-green, 0.6);
    border-radius: 1rem;
    color: $white;

    svg {
      margin-left: 1rem;
    }
  }

  &-text {
    background: transparent;
    color: $secondary-green-500;
    text-decoration: underline;
  }

  &-arrow {
    background: transparent;
    padding: 0;
  }

  &:disabled {
    background-color: $secondary-grey-200;
    color: $black;
    cursor: not-allowed;
  }
}

@media (min-width: $ht-lrg) {
  .button {
    font-size: 16px;
  }
}