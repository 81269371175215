@import '~src/css/vars.scss';

.modal {
  background: rgba(0 ,0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.modalMain {
  background: $primary-dark-green;
  border-radius: 25px;
  color: $white;
  height: auto;
  left: 50%;
  padding: 1em;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 10;

  .closeButton {
    background-color: transparent;
    color: $white;
    line-height: 0px;
    padding: 0px;
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}
