@import '~src/css/vars.scss';

.title {
    @include h4;
    padding: 28px 1.5em;
    text-align: center;
    font-size: 2rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.button {
    width: 100%;
}

.inputAndError {
    .radioContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 2em;

        .radioOption {
            display: flex;
            flex-direction: column;
            margin: 1em;

            input[type='radio'] {
                opacity: 0;
                position: absolute;
                z-index: 100;
            }

            label {
                align-items: center;
                background: $carbon13;
                border-radius: 50%;
                color: $black;
                cursor: pointer;
                display: flex;
                font-size: 1rem;
                font-weight: 400;
                height: 4em;
                justify-content: center;
                width: 4em;
            }

            input {
                width: 20px;
            }
        }

        input[type='radio']:checked + label {
            background: $primary-dark-green;
            color: $white;
        }

        input[type='radio']:focus-visible + label {
            outline: 2px solid $blue;
        }
    }
    .validationError {
        color: $red-error;
        margin-bottom: 1em;
    }
}
