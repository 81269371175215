@import 'src/css/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
    padding: 1rem;

    .account_header {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .name_section {
        display: flex;
        position: relative;

        .name {
            color: $primary-dark-green;
            font-size: 18px;
        }
    }

    .info {
        align-items: center;
        border-radius: 0.5em;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        height: 3.5em;
        margin-top: 0.5em;
        min-height: 3.5em;

        svg {
            margin: 0 0.5em 0 0.5em;

            path {
                stroke: $primary-dark-green;
            }
        }
    }

    .backButton {
        svg {
            transform: rotate(-90deg);

            path {
                stroke: $primary-dark-green;
            }
        }
    }

    a {
        color: $yellow;
        font-size: 1.5em;
        font-weight: 600;
        text-decoration: none;
    }
}

@media (min-width: $ht-lrg) {
    .container {
        padding: 0;
    }
}
