@import '~src/css/vars.scss';

.jobOfferDetails {
    background-color: $white;
    padding: 2rem 25px 25px;

    .jobDetailsText {
        display: block;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    .summary {
        margin: 20px 0;
        padding: 0;
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        row-gap: 0.5rem;

        .tag {
            align-items: center;
            display: flex;
            margin-bottom: 0.5rem;

            span:first-of-type {
                display: flex;
                height: 22px;
                width: 22px;

                img {
                    width: 100%;
                }
            }

            span:last-of-type {
                padding: 0.1rem 0.4rem;
            }

            svg path {
                stroke: $primary-dark-green;
            }
        }
    }
}

.customerAvailabilityContainer {
    padding: 0 25px 25px;

    .title {
        font-size: 1.2em;
        font-weight: 500;
        line-height: 32px;
        margin: 20px 0 0;
        padding: 0;
    }

    .subtitle {
        color: $carbon10;
        font-size: 1em;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }

    .dates {
        margin-right: 5px;
        margin-top: 20px;
    }

    .dateWrapper {
        cursor: pointer;
        margin-right: 15px;
    }
}

.actionsContainer {
    display: flex;
    gap: 1.25em;
    justify-content: center;
    margin: 1rem;

    .actionButton {
        padding: 0 45px;
        text-align: center;
        width: 100%;
        width: 140px;
        &:first-of-type {
            &:hover {
                & svg {
                    > path {
                        stroke: $white;
                    }
                }
            }
        }
    }
}

.jobOfferGoneText {
    margin: 10px 0;
    padding: 0;
    text-align: center;
}

.jobOfferGoneButton {
    display: block;
    margin: 20px auto;
}

@media (min-width: 400px) {
    .actionsContainer {
        justify-content: space-between;
        margin: 2rem;
    }
}
