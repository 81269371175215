@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.title {
    @include h5;
    margin: 1.5em 0 0.5em;
    padding: 0;
}

.inputAndError {
    margin: 1em 0 0.25em;
    .dropdown {
        padding: 0;

        ul li::before {
            content: none;
        }

        button {
            border-radius: 0;
        }

        span {
            line-height: normal;
        }

        label {
            letter-spacing: -0.24px;
            font-size: 1.25rem;
        }
    }

    @media (min-width: $ht-lrg) {
        .dropdown {
            label {
                font-size: 1.5rem;
            }
        }
    }
}
