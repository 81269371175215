@import '~src/css/vars';

.navbar {
    background-color: $primary-off-white;
    border-bottom: 1px solid rgba($primary-dark-green, 0.3);

    &[data-version='dark'] {
        background-color: $primary-mid-green-2;

        svg {
            path {
                fill: $primary-yellow;
            }
        }

        .content {
            span {
                color: $primary-off-white;

                a {
                    color: $primary-highlight-green;
                }
            }
        }
    }

    .content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-inline: auto;
        padding: 1.8rem 2.8rem;

        svg {
            height: auto;
            vertical-align: middle;
            width: 2rem;
        }

        .hideMobile {
            display: block;
        }

        .hideDesktop {
            display: none;
        }

        .logo {
            max-width: 160px;

            svg {
                height: auto;
                width: 100%;
            }
        }
    }

    @media (max-width: $breakpoint-mobile) {
        margin-bottom: 0;

        .content {
            padding: 20px;

            span {
                font-size: 1rem;
                line-height: 1.5;
                max-width: 120px;
                text-align: center;
            }

            .hideMobile {
                display: none;
            }

            .hideDesktop {
                display: block;

                svg {
                    height: 21px;

                    path {
                        fill: transparent;
                        stroke: $primary-highlight-green;
                    }
                }
            }

            .logo {
                svg {
                    height: 22px;
                }
            }
        }
    }
}

.banner {
    background-color: #b91818;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    z-index: 2;

    .bannerMessage {
        font-size: 14px;
        line-height: 25px;
    }

    .bannerLink {
        color: $white;
        font-weight: 600;
    }
}
