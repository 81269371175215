@import '~src/css/vars.scss';

.container {
  background-color: $primary-mid-green-2;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  padding-bottom: 80px;

  .banner {
    color: $white;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1200px;
    padding: 24px;
    text-align: center;

    .bannerMessage {
      margin: 0 0 16px 0;
      padding: 0 15px;

      .banner-link {
        margin-left: 1em;
      }
    }

    .bannerLink {
      color: $white;
      font-weight: 600;
    }

    .bannerButton {
      margin: 0 auto;
    }
  }

  .authStyle {
    bottom: 0px;
  }

  .banner.nodisplay {
    display: none;
  }

  @media (min-width: $ht-lrg) {
    bottom: 0;
    padding-bottom: 0px;

    .banner {
      align-items: center;
      bottom: 0em;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;

      .bannerMessage {
        margin: 0;
        padding: 0;
      }

      .bannerButton {
        margin: 0;
      }
    }
  }
}

.authContainerStyle {
  padding-bottom: 0px;
}