@import '~src/css/vars.scss';

.checkboxContainer {
    label {
        display: flex;
        align-items: center;
        gap: 0.75rem;
    }

    input {
        &:checked {
            + span {
                background-color: $secondary-purple-500;
                border: 2px solid $secondary-purple-500;

                svg {
                    opacity: 1;
                }
            }
        }

        opacity: 0;
        position: absolute;
        z-index: -1;

        + span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.125rem;
            width: 1.125rem;
            border: 2px solid $secondary-purple-900;
            border-radius: 5px;
            cursor: pointer;

            svg {
                opacity: 0;
            }
        }
    }
}
