@import '~src/css/vars.scss';

.textArea,
input.textAreaSingle {
    border: 2px solid $gray-light;
    margin-bottom: 1em;
}

.button {
    width: 100%;
}

.title {
    @include h3;
    padding: 28px 1.5em;
    text-align: center;
    font-size: 2rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.inputAndError {
    width: 100%;
}

.errorBorder {
    color: $red-error;
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;
}

.formControl {
    margin-bottom: 1em;

    label {
        align-items: center;
        display: flex;
        font-size: 18px;
        font-weight: 600;

        span {
            display: block;
            min-width: 50%;
        }

        select {
            line-height: 1.2;
        }
    }
}

.partsNeeded {
    label {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
}

.authRequest {
    display: flex;
    flex-direction: column;
}

.formHeader {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.5em;
}

.subText {
    color: $carbon;
    font-weight: 400;

    margin: 0.5em 0em;
    text-align: center;
}

.checkbox {
    margin-right: 0.5rem;
}

.checkboxContainer {
    align-items: flex-start;
    display: flex;
    margin-bottom: 1rem;
}
