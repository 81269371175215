@import '~src/css/vars.scss';

.notFoundPage {
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$header-height} - (#{$text-padding} * 2));
    justify-content: flex-start;
    padding: $text-padding;
    text-align: center;

    svg {
        height: 48px;
        margin: 0 auto;
        width: 48px;

        path,
        circle {
            stroke: $primary-mid-green-2;
        }
    }

    h1 {
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.5px;
        line-height: 27px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.2px;
        line-height: 28px;
        text-align: center;
    }
}
