@import '~src/css/vars.scss';

.heading {
    gap: 1.6rem;
    margin: 0 auto;
    width: 66%;
    max-width: $breakpoint-desktop;
    padding: 1.6rem 0;
    border-radius: 40px 40px 40px 0px;
    background-color: $primary-mid-green-2;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 46px;

    h1 {
        margin: 1.5rem 0;
        @include h2;
    }

    .subheading {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(50%);
        bottom: 0;
        padding: 10px 25px 6px 25px;
        border-radius: 6px;
        background-color: $primary-highlight-green;
    }

    h2 {
        @include h2;
        color: $primary-off-white;
        margin: 20px;
    }

    h4 {
        @include h4;
        color: $primary-dark-green;
        margin: 0px;
        font-size: 16px;
    }

    @media (max-width: $breakpoint-mobile) {
        margin: 1rem;
        padding: 0.25rem 1rem;
        border-radius: 1.25rem 1.25rem 1.25rem 0px;
        display: block;
        width: 80%;
        height: 95px;

        .subheading {
            min-width: 120px;
            height: 25px;
            background-color: $primary-highlight-green;
        }

        h1 {
            margin: 1.5rem 0;
            @include h2;
        }
    }
}
