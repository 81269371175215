@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.title {
    @include h5;
    margin: 1.5em 0 0.5em;
    padding: 0;
}

.button {
    width: 100%;
}

.inputAndError {
    .radioContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0.5em;
        justify-content: center;
        margin-bottom: 1em;

        .radioOption {
            flex-direction: column;
            margin: 0.25em 0;
            padding-left: 1em;

            input[type='radio'] {
                opacity: 0;
                position: absolute;
                z-index: 100;
            }

            label {
                align-items: center;
                color: $carbon1;
                cursor: pointer;
                display: flex;
                font-weight: 400;
                padding-left: 1.5em;
                padding-top: 0.2rem;
                position: relative;

                $diameter: 1.5em;

                &::before {
                    background: #fff;
                    border: 1px solid $carbon11;
                    border-radius: 50%;
                    content: ' ';
                    height: $diameter;
                    left: 0;
                    position: absolute;
                    top: 13px;
                    transform: translate(-50%, -50%);
                    width: $diameter;
                }

                &::after {
                    background: #fff;
                    border-radius: 50%;
                    content: ' ';
                    height: calc($diameter / 1.6);
                    left: 0;
                    position: absolute;
                    top: 13px;
                    transform: translate(-50%, -50%);
                    width: calc($diameter / 1.6);
                }
            }

            input {
                width: 20px;
            }
        }

        input[type='radio']:checked + label {
            &::after {
                background: $primary-dark-green;
            }
        }

        input[type='radio']:focus-visible + label {
            outline: 2px solid $blue;
        }
    }
}
