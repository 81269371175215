@import '~src/css/vars.scss';

.select {
  background: $white;
  display: inline-block;
  color: $carbon;
  line-height: 50px;
  font-size: 16px;
  border: 2px solid $gray-light;
  border-radius: 2em;
  padding: 0.25em 2em 0.25em 1.25em;

  // hide the default arrow
  appearance: none;

  // custom arrow
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 15px) calc(50%), calc(100% - 10px) calc(50%),
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}
