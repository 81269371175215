@import "src/css/vars.scss";

.completeContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    max-width: 400px;
    margin: 0 auto;

    .title {
        font-size: 22px;
        margin: 1em 0;
        text-align: center;
    }

    .extraCopy {
        color: $carbon4;
        font-weight: 400;
        margin: 0.5em 0 1.5em;
        text-align: center;
    }

    .icon {
        height: 3em;
        padding: 28px 0 14px;
        width: auto;
    }
}
