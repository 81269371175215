@import '~src/css/vars.scss';

.container {
    @include normal_border;
    background-color: $white;
    color: $primary-dark-green;
    display: flex;
    flex-direction: column;
    font-family: $font-body;
    height: 100vh;
    min-height: 100vh;
    padding: 24px 31px;

    .marketingContainer {
        margin-top: 3.8rem;

        .giftCard {
            color: $secondary-green-500;
        }

        .terms {
            color: $secondary-green-500;
        }

        h2 {
            font-size: 1.5rem;
        }

        .description {
            margin: 1rem 0;
        }
        .marketingOptions {
            margin-top: 2rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .marketingCheckboxContainer {
                display: flex;
                margin: 1rem 0 2rem 0;
                font-weight: $base-font-weight;
                .checkboxes {
                    padding-right: 2rem;
                    display: flex;
                    align-items: center;
                    line-height: 1rem;

                    input {
                        &:checked {
                            + span {
                                background-color: $primary-dark-green;
                            }
                        }
                    }
                    input,
                    span {
                        border: 2px solid $primary-dark-green;
                    }
                }
            }
        }
    }

    .account_header {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1.7rem;

        h2 {
            @include h2;
            margin: 0;
        }
    }

    .name_section {
        margin-bottom: 1.3rem;
        position: relative;

        .name {
            font-size: 1.5rem;
        }
    }

    .info {
        align-items: center;
        display: flex;
        margin-bottom: 10px;

        svg {
            margin: 0 1em 0 0;

            path {
                stroke: $primary-dark-green;
            }
        }
    }

    .button_container {
        margin-top: 3em;
    }

    .feedback_button {
        width: 25em;
    }
}

@media (min-width: $ht-lrg) {
    .container {
        border-radius: 20px;
        margin-top: 96px;
        padding: 0;
        padding: 36px 42px;

        .account_header {
            h2 {
                @include h2;
            }
        }

        .description {
            padding-right: 4rem;
        }
    }
}
