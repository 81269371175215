@import 'src/css/vars.scss';

.extraCopy {
  font-weight: 400;
  margin-bottom: 2.5em;
  margin-top: 1.4em;
  text-align: center;
}

.title {
  padding-bottom: 14px;
  text-align: center;
}

.shield {
  height: 3em;
  padding: 28px 0 14px;
  width: auto;

  path {
    stroke: $primary-dark-green;
  }
}

.button {
  width: 100%;
}
