@import 'src/css/vars.scss';

.claimFormErrorBar {
    margin: auto;
    margin-top: 10rem;
    max-width: 50rem;
}

.banner {
    color: $secondary-turquoise-900;
    background-color: $secondary-turquoise-100;
    margin: 0;
    padding: 20px;
    font-size: 1.1rem;
    > a {
        color: $secondary-green-500;
        font-size: inherit;
    }
}
