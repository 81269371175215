@import '~src/css/vars.scss';

.heading {
    color: $primary-dark-green;
    padding: 12px 2em;
    font-size: 2rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.dateFormWrapper {
    width: 100%;

    h2 {
        color: $primary-dark-green;
        margin-top: 1.5rem;

        span:first-of-type {
            font-weight: 700;
        }

        span:last-of-type {
            display: none;
        }
    }

    h3 {
        color: $primary-dark-green;
        margin-bottom: 0.875rem;
    }
}

.timeSlotWrapper {
    width: 100%;

    h3 {
        color: $primary-dark-green;
    }
}

.button {
    width: 100%;
}
