@import '~src/css/vars.scss';

.modal {
    p {
        display: flex;
        justify-content: center;
        padding-bottom: 0px;
        margin-top: 1em;
    }
    .modalButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        .skipButton {
            border-color: white;
            color: white;
        }
    }
}

.container {
    padding: 1.2rem 25px 25px;

    &.cancelContainer {
        background-color: $white;
        color: $carbon1;

        select {
            line-height: 1.2;
        }

        .otherReason {
            margin-top: 1.25em;

            label {
                font-size: 1em;
                font-weight: 600;
                margin-bottom: 0.75em;
                display: block;
            }

            .textArea {
                border: 2px solid $gray-light;
            }
        }

        h3 {
            color: $carbon1;
            font-weight: 600;
            font-size: 1.375rem;
            margin-bottom: 1rem;
        }

        h5 {
            font-weight: 600;
            font-size: 1rem;
            color: $carbon1;
            margin-bottom: 1rem;
        }

        span:first-of-type {
            color: $carbon1;
            font-weight: 600;
            font-size: 1.375rem;
            display: inline-block;
            margin-top: 30px;
        }

        // over ridding span styling
        ul {
            span {
                font-size: 1rem !important;
                margin-top: 0 !important;
            }
        }
    }
    &.rescheduleAlertContainer {
        background-color: white;
        button {
            background-color: inherit;
            padding: 0;
            float: right;
        }
        svg {
            path {
                stroke: $carbon4;
            }
        }
        p {
            margin: 0;
            text-align: center;

            &:first-of-type {
                color: $carbon1;
                font-weight: 600;
                font-size: 1.4em;
                margin-top: 60px;
            }
            &:last-of-type {
                color: $carbon5;
                font-size: 1.2em;
                font-weight: 500;

                a {
                    color: $purple-medium;
                    text-decoration: none;
                    font-weight: 500;
                }
            }
        }
    }
}

.detailActionButtons {
    margin-top: 1.25em;
    width: 350px;
    max-width: 100%;
    justify-content: center;
}

.actionButton {
    svg {
        height: 20px;
        width: 20px;
        margin-right: 0.5em;
    }

    span {
        white-space: nowrap;
    }
}

.title {
    color: $carbon;
    display: block;
    font-size: 0.875rem;
    text-transform: uppercase;
}

.overview {
    .summary {
        display: block;
        font-size: 20px;
        line-height: 32px;
        margin: 15px 0;
    }

    .flags {
        align-items: center;
        display: flex;
        justify-content: space-between;

        div {
            align-items: center;
            justify-content: left;
            display: flex;
            text-transform: uppercase;
            font-size: 14px;

            svg {
                height: 20px;
                margin-right: 5px;
            }
        }
    }

    .separator {
        background-color: $carbon4;
        display: block;
        height: 1px;
        margin: 25px 0;
        width: 100%;
    }

    .customer {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;
        line-height: 32px;
        font-size: 14px;

        .detailsLabel {
            font-weight: 600;
        }

        .telephone {
            margin-top: 30px;
        }
    }
}

@media (min-width: $ht-lrg) {
    .actionButton {
        justify-content: center;
    }

    .startBtn {
        grid-column-start: 4;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 4;
    }
}

.actionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1rem;
    gap: 1.25em;

    button {
        width: 100%;

        &[data-variant='secondary'] {
            background-color: transparent;
            color: $carbon1;
        }
    }
}
