@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.emailSignUpSection {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 2.4rem;
    text-align: center;

    h1 {
        color: $primary-off-white;
    }

    h2 {
        color: $primary-dark-green;
        font-size: 22px;
        font-weight: 400;
        margin-bottom: 50px;
    }

    .activationDetailsForm {
        width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $font-body;

        & Button {
            margin-top: 2.6em;
            margin-bottom: 2em;
        }

        & :global(.error-bar) {
            margin: 1.5em 0 0 0;
        }
    }

    .formControl {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
            display: flex;
            width: 100%;
            max-width: 400px;
            height: 16px;
            justify-content: flex-start;
            align-items: center;
            flex-shrink: 0;
            color: $primary-dark-green;
            padding-left: 25px;
            margin-bottom: 10px;
            margin-left: -25px;
        }

        label {
            width: 100%;
        }

        Input {
            width: 100%;
            max-width: 600px;
            margin-left: -25px;
            height: 48px;
            flex-shrink: 0;
            border-radius: 25px;
            border: 1px solid $primary-dark-green;
            background: $white;
        }
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        margin-top: 18px;

        Button {
            width: 100%;
            max-width: 600px;
            height: 48px;
            margin-top: 0;
        }
    }

    .link {
        width: 75%;
    }

    .authFormErrorBar {
        margin: 20px 0 0 0;
        border-radius: 25px;
    }
}

@media (max-width: 600px) {
    .emailSignUpSection {
        .formControl {
            width: 80%;
        }
    }
}
