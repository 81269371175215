@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.applianceTitle {
    display: flex;
    gap: 20px;
    align-items: center;
    pointer-events: none;

    .title {
        @include h3;
        margin: 0;
    }
}

.title {
    @include h5;
    margin: 1.5em 0 0.5em;
    padding: 0;
}

.inputAndError {
    .dropdownContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 1em;
    }
}
