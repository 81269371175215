@import '~src/css/vars.scss';

.withCookieBanner {
    margin-bottom: 10em;

    @media (min-width: $ht-lrg) {
        margin-bottom: 0em;
    }
}

.rebrandBody {
    background-image: none;
    background-color: $primary-off-white;
    min-height: 100vh;

    .input {
        border: 1px solid $secondary-green-900;
    }
}

.defaultBody {
    background-color: $primary-off-white;
}
