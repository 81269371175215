@import '~src/css/vars.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    button {
        width: 100%;
        max-width: 30em;
    }

    input[type='text'] {
        border: 2px solid $gray-light;
        border-radius: 3em;
        height: 3em;
        text-align: center;

        padding: 0;

        line-height: 35px;
        font-size: 30px;
        font-weight: 400;
        color: $carbon;

        margin-bottom: 1.5em;
    }
}

.title {
    padding: 1em 1.5em;
    text-align: center;
    font-size: 1.5rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.inputAndError {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 30em;
}

.errorBorder {
    border-color: red !important;
}

.validationError {
    margin-top: -2.5em;
    margin-bottom: 1em;
    color: $red-error;
}
