@import 'src/css/vars.scss';

.container {
  display: grid;
  grid-row-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .gridHeaderWrapper {
    display: contents;
    text-align: left;
    
    p {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .gridRowWrapper {
    align-items: center;
    display: contents;

    .gridItem {
      align-items: center;
      display: grid;
      justify-items: left;
      text-align: center;

      p {
        padding-bottom: 0;
      }

      .downloadButton {
        background-color: inherit;
        line-height: 0;
      }
    }
  }
}
