@import 'src/css/vars.scss';

.container {
    background-color: $primary-dark-green;
    bottom: 0;
    display: flex;
    flex-direction: row;
    font-family: $font-body;
    font-size: 12px;
    gap: 0.5rem;
    justify-content: center;
    padding: 1rem 1rem 0;
    position: fixed;
    width: calc(100% - 2 * #{$navbar_padding});
    z-index: 4;
}

.routesContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: row;

    &a,
    a:active,
    a:link,
    a:visited {
        color: $white;
    }
}

.selected {
    border-bottom: 3px solid $primary-highlight-green;
}

.notSelected {
    border-bottom: 3px solid transparent;
}

.disabledLink {
    filter: opacity(0.3);
    pointer-events: none;
}

a.navbarIcon {
    align-items: center;
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 1rem;
    padding: 0.3rem 0 1rem;
    padding-top: 0.5rem;
    text-align: center;
    text-decoration: none;
    width: 4rem;

    svg {
        flex: 1 0 24px;
        path {
            stroke: $primary-off-white;
        }
    }
}

@media (min-width: $ht-lrg) {
    .container {
        border-bottom: 1px solid $carbon4;
        bottom: auto;
        height: $navbar_height;
        padding: 1rem;
        position: relative;
        width: auto;
    }

    a.navbarIcon {
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        padding: 0.3rem 0 13px;

        svg {
            margin-bottom: 3px;
        }
    }
}
