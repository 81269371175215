@import '~src/css/vars.scss';

.button {
    width: 100%;
}

.title {
    @include h3;
    padding: 28px;
    font-size: 2rem;
    text-align: center;
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;

    a {
        color: inherit;
        font-weight: bold;
    }
}

.addPart {
    background: inherit;

    border: 2px solid $carbon5;
    display: flex;
    flex-direction: row;
    font-size: 2em;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 20px;
    width: 100%;
    p {
        margin: 10px;
        padding: 0;
        text-align: center;
        :last-of-type {
            font-size: 2em;
        }
    }
}
