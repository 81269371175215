@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.title {
    @include h5;
    margin: 1.5em 0 0.5em;
    padding: 0;
}

.inputAndError {
    .dropdownMonth {
        flex: 0 0 13em;
    }
    .dropdownYear {
        flex: 0 1 13em;
    }
    .fnolformCalendar {
        border-radius: 25px;
        color: white;
        margin-top: 20px;
        max-width: 50%;
        padding: 1rem 1.5rem;
        width: fit-content;
        background-color: $red-error;
        font-weight: 600;
    }
    .dropdownCalendarContainer {
        display: flex;
        margin-top: 1.25em;
        gap: 20px;
        margin-bottom: 1em;
        position: relative;
        z-index: 0;
    }
}
