@import '~src/css/vars.scss';

.dashboard {
    .title {
        margin: 3.2rem 0;
        padding: 0 1rem;
        text-align: center;
        @include h2;
    }

    .carouselContainer {
        margin-bottom: 6rem;
        padding: 0 0 0 2.5rem;
        position: relative;

        .options {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;

            > .option {
                display: inline-block;
                flex: 0 0 auto;
                margin-right: 32px;
                position: relative;
                text-decoration: none;

                .icon {
                    background-color: $primary-yellow;
                    border-radius: calc(194px / 2);
                    display: block;
                    height: 170px;
                    margin-bottom: 20px;
                    position: relative;
                    width: 170px;
                }

                svg {
                    height: 96px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 96px;
                }

                h3 {
                    color: $primary-dark-green;
                    display: block;
                    font-size: 24px;
                    text-align: center;
                    text-decoration: none;
                    width: 100%;
                }

                &:hover:not([data-disabled='true']) {
                    .icon {
                        background-color: $primary-highlight-green;
                    }

                    &[data-icon='BoookARepair'] {
                        svg path,
                        svg circle {
                            stroke: none;
                        }
                    }

                    &[data-icon='AnnualService'] {
                        svg path,
                        svg circle {
                            fill: none;
                        }
                    }

                    &[data-icon='YourAccount'] {
                        svg path,
                        svg circle {
                            fill: none;
                        }
                    }
                }

                &[data-disabled='true'] {
                    cursor: not-allowed;

                    .icon {
                        background-color: $secondary-grey-200;
                    }

                    svg path,
                    svg circle {
                        stroke: $secondary-grey-300;
                    }

                    span {
                        color: $secondary-grey-200;
                    }
                }
            }
        }
    }

    @media (min-width: $breakpoint-mobile) {
        .carouselContainer {
            .hoverbackground {
                display: none;
            }

            .options {
                justify-content: center;

                > .option {
                    margin-right: 100px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.giftCard {
    color: $secondary-purple-500;
}

.terms {
    color: $secondary-green-500;
}

.description {
    padding-right: 4rem;
    margin: 1rem 0;
}

.confirmBtn {
    background-color: $secondary-purple-500;
    color: $white;
    font-size: 1rem;
    font-weight: 500;
    font-style: normal;
}

.confirmBtn:hover,
.confirmBtn:focus {
    background-color: $secondary-purple-900;
}

.confirmBtn:active,
.confirmBtn:visited {
    color: $secondary-purple-100;
}

.checkboxContainer {
    display: flex;
    margin: 1rem 0 2.6rem 0;
    font-weight: $base-font-weight;
    .checkboxes {
        padding-right: 26px;
        color: $secondary-purple-900;
        display: flex;
        align-items: center;
        line-height: 1.8rem;
    }
}
