@import '~src/css/vars.scss';

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.codeInput {
    width: 100%;
    height: 4.2em;
    position: relative;
    overflow: hidden;

    &.filled {
        div {
            border-bottom: 0.1em solid $carbon6;
        }
    }

    &.error {
        div {
            border-bottom: 0.1em solid $red-error;
        }
    }

    :focus {
        outline: none;
    }

    input[type='text'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0px;
        z-index: 99;
        opacity: 0;

        &:focus ~ label {
            div:empty {
                &::before {
                    animation: blink 1s infinite;
                    background-color: $primary-dark-green;
                    content: '';
                    display: block;
                    height: 40px;
                    opacity: 1;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 2px;
                }
            }

            div:empty ~ div {
                &::before {
                    display: none;
                }
            }
        }
    }

    label {
        display: flex;
        flex-direction: row;
        position: absolute;
        gap: 0.5em;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: text;

        div {
            background-color: transparent;
            color: $primary-dark-green;
            text-align: center;
            border: 0;
            border-bottom: 0.1em solid $primary-dark-green;
            border-radius: 0;
            font-size: 2em;
            line-height: 2.2em;
            max-width: 2.2em;
            width: 100%;
            padding: 0;
        }
    }
}
