.stageLoader {
    > div {
        display: flex;
        width: 100%;
        height: 450px;
        justify-content: center;
        align-items: center;

        > div {
            position: relative;
            top: 0;
            left: 0;
        }
    }
}
