@import 'src/css/vars.scss';

.jobAgendas {
  .navContainer {
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: $ht-lrg) {
      border-top: 0px;
      justify-content: flex-start;
    }
  }

  .agendaButton {
    background-color: $white;
    border: none;
    border-radius: 0px;
    color: $primary-dark-green;
    font-weight: 200;
    width: 50%;

    @media (min-width: $ht-lrg) {
      width: min-content;
    }
  }

  .selected {
    border-bottom: 2px solid $primary-dark-green;
  }

  .notSelected {
    border-bottom: 2px solid transparent;
  }
}
