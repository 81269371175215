@import 'src/css/vars.scss';

.dateHeader {
    padding: 1em 0.5em;
    position: relative;

    .backBtn:hover {
        background: none;
    }

    .backBtn {
        background: none;
        border: none;
        bottom: 0;
        cursor: pointer;
        line-height: 1.2;
        padding: 0;
        position: absolute;
        top: 0;

        svg {
            transform: rotate(-90deg);
            path {
                stroke: $primary-dark-green;
            }
        }

        &:hover {
            background: none;
        }
    }

    > div {
        text-align: center;
    }

    span {
        font-size: 20px;
        font-weight: 600;
        line-height: 27px;
    }

    span:first-of-type {
        color: $primary-dark-green;
    }

    span:last-of-type {
        color: $primary-mid-green-2;
    }
}
