@import 'src/css/vars.scss';

.dateHeader {
  padding: 1em 0.5em;

  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
  }

  span:first-of-type {
    color: $primary-dark-green;
  }

  span:last-of-type {
    color: $primary-mid-green-2;
  }
}
