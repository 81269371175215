@import '~src/css/vars.scss';

.banner {
    background-color: $secondary-purple-100;
    cursor: initial;
    padding: 24px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    margin: 1.3rem 0;

    &[data-link='true'] {
        cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4,
    p {
        color: $secondary-purple-900;
        margin: 0 0 5px 0;
        padding: 0;
        text-align: center;
    }

    .container {
        align-items: center;
        display: flex;
        flex-direction: column;

        .subtitle {
            text-align: left;
            margin: 0;
            min-width: 16rem;
            font-size: 1rem;
        }
    }

    .bannerLink {
        align-items: center;
        background: $white;
        border-radius: 5px;
        display: flex;
        margin-top: 15px;
        padding: 9px 22px;

        > div {
            font-size: 14px;
            font-weight: 400;
            padding-right: 10px;
            width: 80%;

            p:first-of-type {
                font-weight: 700;
            }

            p:last-of-type {
                font-weight: 400;
                margin: 0;
            }
        }

        a {
            color: $secondary-green-500;
            font-family: $font-body;
            text-decoration: underline;
            width: 20%;
        }

        p {
            text-align: left;
            font-size: 1rem;
        }

        button {
            padding: 1.8rem;
        }
    }

    &[data-version='green'] {
        background-color: $primary-highlight-green;
        justify-content: center;

        .container {
            flex-direction: row;
        }

        h1,
        h2,
        h3,
        h4,
        p {
            color: $primary-dark-green;
            text-align: left;
        }

        button {
            margin-top: 17px;
        }
    }

    &[data-version='purple'] {
        padding: 2rem 4rem;

        h3 {
            display: none;
        }

        @media (min-width: $ht-lrg) {
            justify-content: center;
            padding: 2rem 7rem;

            .container {
                flex-direction: row;
                justify-content: center;
            }

            h4 {
                @include h4;
                border-right: solid 3px;
                display: flex;
                flex: 0 0 300px;
                // font-weight: 400;
                margin-bottom: 0;
                margin-right: 15px;
                padding-right: 15px;
                text-align: left;
            }

            h3 {
                @include bodytext;
                display: block;
                margin-right: 15px;
            }
        }
    }

    @media (min-width: $ht-lrg) {
        align-items: center;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 24px 29px;

        h1 {
            @include h2;

            margin-bottom: 0;
        }

        .bannerLink {
            margin-top: 0;
        }

        .title {
            text-wrap: nowrap;
        }

        &[data-version='green'] {
            h1,
            h2 {
                @include h2;

                margin-bottom: 0;
            }

            p {
                @include bodytext;
                font-size: 1rem;

                margin-bottom: 0;
            }

            button {
                margin-top: 0;
            }
        }

        svg {
            margin-left: 2rem;
        }
    }
}
