@import '~src/css/vars.scss';

.textArea,
input.textAreaSingle {
    border: 2px solid $gray-light;
    margin-bottom: 14px;
}

.button {
    width: 100%;
}

.title {
    padding: 28px 1.5em;
    text-align: center;
    font-size: 1.5rem;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.inputAndError {
    width: 100%;
}

.errorBorder {
    color: $red-error;
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;
}

.formControl {
    margin-bottom: 1em;

    label {
        align-items: center;
        display: flex;
        font-size: 22px;

        input {
            border: 2px solid $gray-light;
            border-radius: 3em;
        }

        span {
            display: block;
            min-width: 40%;
        }

        select {
            line-height: 1.2;
        }
    }
}
