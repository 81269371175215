@import '~src/css/vars.scss';

.title {
    @include h4;
    padding: 28px 1.5em;
    text-align: center;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.checkboxWrapper {
    display: flex;
    gap: 1em;
    margin-bottom: 1em;

    label {
        font-weight: 400;
    }

    input {
        width: 20px;
    }
}
