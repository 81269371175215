@import '~src/css/vars.scss';

.title {
    @include h2;
    margin: 1.5em 0 0.5em;
    padding: 0;

    @media (min-width: $ht-lrg) {
        @include h3;
    }
}

.button {
    width: 100%;
}

.inputAndError {
    .dropdownContainer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 1em;

        select {
            border: 1px solid $carbon11;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: 16px;
            margin-top: 0.25em;
            padding: 0 0.6em;
            width: 100%;
        }
    }

    .otherInputContainer {
        margin-bottom: 1em;

        input {
            border: 1px solid $carbon11;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: 16px;
            margin-top: 0.25em;
            padding: 16px 25px;
            width: 100%;
        }
    }
}
