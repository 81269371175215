@import '~src/css/vars.scss';

.info {
    background: $white;
    padding: 15px;
  
  .date {
    font-size: 1.2em;
    font-weight: 600;
    padding-bottom: 10px;
    .year {
      color: $carbon;
    }
  }
  
  .calendar {
    display: flex;
  
    .container {
      display: block;
      margin-right: 10px;
      min-height: 92px;
      scroll-snap-align: center;
    }
  }
  
  .timeOfDay {
    font-size: 1em;
  }
  
  .timeSlot {
    font-size: 1em;
    padding: 10px 0;
  }
}