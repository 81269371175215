@import '~src/css/vars.scss';

.codeForm {
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: $font-body;

    & Button {
        margin-top: 2.6em;
        margin-bottom: 2em;
    }

    & :global(.error-bar) {
        margin: 1.5em 0 0 0;
    }
}

.instructions {
    font-size: 1em;
    line-height: 1.5em;
    letter-spacing: -0.2px;
    color: $primary-dark-green;
    margin-bottom: 1.8em;
    margin-top: 1.8em;
    padding: 0px 15px 0px 15px;
    font-family: $font-body;
}

.resendWrapper {
    display: flex;
    flex-direction: row;
}

.resendLink {
    display: flex;
    align-items: center;
    background: none;
    color: $primary-dark-green;
    text-decoration: underline;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 1.5em;
    padding-right: 10px;

    &:hover {
        cursor: pointer;
    }
}

.resendAlert {
    color: #83b002;
    line-height: 1.5em;
    margin-right: 5px;
}

.resendIcon {
    width: 20px;
    height: 20px;
    padding-left: 15px;
}

.backButton {
    background-color: transparent;
    margin-top: 2.5rem;
}

.error-bar-resentMessage {
    max-width: 410px;
    margin-top: 30px;
    margin-bottom: -10px;
}

.error {
    color: $error;
    margin: 30px 0 0 0;
    padding: 0px;
}

.placeholder {
    width: 35px;
    height: 20px;
}
