@import '~src/css/vars';

.inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 120%;

    label {
        @include h3;

        color: $primary-dark-green;
        margin-bottom: 1rem;
    }

    input {
        appearance: none;
        background-color: #fff;
        border: 1px solid $secondary-green-900;
        border-radius: 3rem;
        color: $primary-dark-green;
        display: block;
        font-family: $font-body;
        width: calc(100% - 40px);

        &:disabled {
            background-color: $secondary-grey-100;
            cursor: not-allowed;
        }
    }

    &.error {
        label {
            color: $error;
        }

        input {
            border-color: $error;
            color: $error;
        }
    }

    .errorMessage {
        color: $error;
        font-family: $font-body;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: -0.5rem;
        padding-left: 20px;
        text-align: left;
    }
}
