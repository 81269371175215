@import 'src/css/vars.scss';

.hiddenStage {
    display: none;
}

.fullWidth {
    @media (max-width: $breakpoint-mobile) {
        border: none;
        border-radius: 0;
        margin: 0;
    }
}
