@import 'src/css/vars.scss';

.textContainer {
    .title,
    h4 {
        margin-bottom: 0.75em;
        font-size: 1.2rem;
    }

    h2 {
        @include h2;
    }

    p {
        margin: 0 0 1em;
    }

    button {
        margin-bottom: 20px;
    }

    hr {
        border-bottom-color: $carbon13;
        border-top: none;
        margin-bottom: 0;
        margin-top: 1.75em;
    }
}
