@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.notCustomerYet {
    a {
        color: $primary-dark-green;
        font-family: $font-body;
    }
}

.loginSection {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
        color: $primary-off-white;
    }

    h2 {
        color: $primary-dark-green;
        font-weight: 400;
        font-size: 22px;
        margin-bottom: 50px;
    }

    .bottomImage {
        display: none;
    }

    @media (min-width: 1200px) {
        .bottomImage {
            display: block;
            bottom: 0;
            left: 62%;
            width: 1200px;
            position: fixed;
            transform: translateX(-50%);
            z-index: -1;
        }
    }
}
