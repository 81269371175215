@import '~src/css/vars.scss';

.container {
    background-color: $primary-dark-green;
    border-radius: 8px;
    box-shadow: 0px 7px 7px 1px #888888;
    color: $white;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    margin-top: 20px;
    padding: 20px;
    width: 90%;
    z-index: 1;

    .heading {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0;
        margin-bottom: 20px;
    }

    .title {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
    }

    .closeButton {
        background-color: inherit;
        line-height: 0;
        margin: 0;
        padding: 0;
    }

    .paragraph {
        font-size: 13px;
        font-weight: 400;
        margin: 15px 0 6px 0;
        padding: 0;
    }

    .addButton {
        margin-top: 30px;
        width: 100%;
    }

    input {
        box-sizing: border-box;

        &:focus {
            border: 3px solid $yellow;
            outline: none;
        }
    }

    .validationError {
        color: $red-error;
        margin-top: 20px;
    }

    .input {
        border: 2px solid #d8dbdd;
        border-radius: 3em;
        color: #788088;
        font-size: 16px;
        height: 3em;
        height: 50px;
        line-height: 50px;
        line-height: 20px;
        padding: 0 20px;
        width: 100%;
        &::-webkit-calendar-picker-indicator {
            opacity: 0;
        }

        &:focus {
            &::-webkit-calendar-picker-indicator {
                opacity: 0;
            }
        }
    }

    .dropdown {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .inputIcon {
        background-color: $white;
        margin-left: -2.8em;
    }

    .searchButton {
        background-color: inherit;
        line-height: 0;
        margin: 0;
        margin-left: -0.5em;
        padding: 0;
    }
}

// Searchable dropdown - to move
.optionsContainer {
    background-color: $white;
    border-radius: 0 0 25px 25px;
    color: $black;
    margin-top: 1.5em;
    max-height: 15em;
    overflow-y: scroll;
    position: absolute;
    width: 100%;

    div:first-child {
        margin-top: 2.5em;
    }
}

.inputContainer {
    align-items: center;
    display: flex;
    width: 100%;
    z-index: 2;
}

.option {
    display: flex;
    flex-direction: row;
    gap: 8px;

    padding: 1em;

    span {
        flex-grow: 1;
    }

    svg {
        min-height: 24px;
        min-width: 24px;
    }
}

.hidden {
    display: none;
}
