@import '~src/css/vars.scss';

.jobAgenda {
  padding: 0rem;

  .dateHeader {
    margin-bottom: .5em;
    margin-top: 2em;
    padding: 0 14px;
  }

  .jobAgendaList {
    margin-bottom: 2em;

    .jobAgendaRow {
      align-items: center;
      background-color: $white;
      border-radius: 0;
      display: flex;
      font-weight: 400;
      justify-content: space-between;
      padding: 1rem 2rem;
      width: 100%;

      &:hover {
        background-color: $primary-highlight-green;
        cursor: pointer;
        transition: 150ms ease-in-out;
      }

      > span {
        font-size: 1.125em;
      }
      
      > div {
        align-items: center;
        display: flex;
        font-size: 0.875em;

        svg {
          height: 24px;
          transform: rotate(90deg);

          path {
            stroke: $primary-dark-green;
          }
        }
      }
    }
  }
}

.noJobs {
  padding: 0 1.375em;
}