@import '~src/css/vars.scss';

.container {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    font-weight: 600;
    padding: 2.3rem 1.6rem;
    position: relative;
}

.question {
    padding: 1em;
}

.section {
    left: 0em;
    position: absolute;
    top: 0em;
}

.backButton {
    background-color: transparent;
    left: 0em;
    line-height: 0;
    padding: 28px 26px 0px;
    position: absolute;

    svg {
        height: 2em;
        width: 2em;

        path {
            stroke: $primary-dark-green;
        }
    }

    &:hover {
        background: none;
    }
}
