@import '~src/css/vars.scss';

.properties {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 50rem;
    padding-left: 0;
    position: relative;
    text-align: center;
}

.subText {
    color: $primary-dark-green;
    margin: 2rem 1rem;
    padding-bottom: 0;
}

.propertiesList {
    margin: 0 1rem;
    color: $primary-dark-green;

    .contractContainer {
        align-items: center;
        background-color: white;
        border-radius: 0 0 0.5rem 0;
        color: black;
        cursor: pointer;
        display: flex;
        flex-direction: row;

        // Override global button style for text
        font-weight: 400;
        justify-content: space-between;
        line-height: 1.3;
        margin-bottom: 1rem;
        padding: 0.8em 2rem;
        width: 100%;

        .coolingOff {
            display: none;
        }

        &.disabled {
            background-color: $carbon9;
            color: $white;
            cursor: not-allowed;
            margin-bottom: 8rem;
            opacity: 0.7;
            position: relative;

            div {
                text-decoration: line-through;
            }

            svg {
                display: none;
            }

            .coolingOff {
                display: block;
                font-size: 14px;
                line-height: 22px;
                position: absolute;
                text-align: left;
                top: calc(100% + 5px);
                color: $primary-dark-green;
            }
        }
    }
}

.propertiesErrorBar {
    margin: auto;
    margin-top: 10rem;
    max-width: 50rem;
}

@media (min-width: $ht-lrg) {
    .propertiesList {
        .contractContainer {
            &.disabled {
                margin-bottom: 5rem;
            }
        }
    }
}
