@import '~src/css/vars.scss';
@import '~src/css/mixins.scss';

.inputTextFormControl {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;

    .title {
        @include h5;
        margin: 1.5em 0 0.25em;
        text-align: left;
    }

    .helper {
        color: $carbon3;
        font-size: 14px;
        padding: 0 0 0.25em;
        text-align: left;
    }

    .inputText {
        border: 1px solid $carbon11;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 16px;
        margin-top: 0.25em;
        padding: 16px 25px;
        width: 100%;
    }
}
