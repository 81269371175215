@import 'src/css/vars.scss';

.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.extraCopy {
    font-weight: 400;
    margin-bottom: 1.5em;
    text-align: center;
}

.reminder {
    margin-bottom: 1.5em;
    text-align: center;
}

.title {
    margin-bottom: 1em;
    text-align: center;
}

.attention {
    path {
        fill: $primary-dark-green;
    }
    width: auto;
}
