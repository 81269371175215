@import '~src/css/vars.scss';

.jobDetails {
    padding: 2rem 1.5rem 1.5rem;

    .title {
        color: $primary-dark-green;
        display: block;
        font-size: 14px;
    }

    .container {
        .summary {
            display: block;
            font-size: 20px;
            line-height: 32px;
            margin: 15px 0;
        }

        .flags {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 1.875em;
            row-gap: 1em;

            div {
                align-items: center;
                display: flex;
                font-size: 14px;
                justify-content: left;
                text-transform: uppercase;

                svg {
                    height: 20px;
                    margin-right: 5px;

                    path {
                        stroke: $primary-dark-green;
                    }
                }
            }
        }

        .separator {
            background-color: $carbon4;
            display: block;
            height: 1px;
            margin: 25px 0;
            width: 100%;
        }

        .customer {
            font-size: 0.875em;

            .idMap {
                align-items: center;
                display: flex;
                font-weight: 400;
                justify-content: space-between;
                margin-bottom: 1em;

                span {
                    color: $primary-dark-green;
                }

                .mapMobile {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    font-size: 0.75em;
                    line-height: 16px;
                    text-transform: uppercase;

                    svg path {
                        stroke: $primary-dark-green;
                    }
                }
            }

            .customerInformation {
                p {
                    margin: 0;
                    padding: 0;
                }
            }

            .telephone {
                margin-top: 3rem;
                margin-bottom: 1rem;

                > a {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    gap: 0.75em;
                    text-decoration: none;
                }
            }
            .telephone2 {
                margin-top: 1rem;
                margin-bottom: 1rem;

                > a {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    gap: 0.75em;
                    text-decoration: none;
                }

                svg path {
                    stroke: $primary-dark-green;
                }
            }
        }
    }

    .actionButtons {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 1em;
    }
}

@media (min-width: $ht-lrg) {
    .jobDetails {
        .container {
            .customer {
                padding-right: 100px;
                width: calc(60% - 100px);

                .idMap .mapMobile {
                    display: none;
                }
            }
        }

        .actionButtons {
            align-items: flex-start;
        }
    }
}
