@import 'src/css/vars.scss';

.textArea,
input.textAreaSingle {
    border: 2px solid $gray-light;
    margin-bottom: 1.5em;
}

.container {
    padding: 28px 1.5em;
}

.title {
    @include h4;
    font-size: 1.5rem;
    margin-top: 20px;
    text-align: center;
}

.tooltipButton {
    background-color: inherit;
    color: $primary-mid-green-2;
    height: 20px;
    line-height: 0;
    padding: 0px 3px;
    padding-bottom: 2px;
    vertical-align: text-bottom;
    width: 20px;
}

.subtitle {
    margin-top: 20px;
    padding-bottom: 0;
    text-align: center;
}

.button {
    display: inline-block;
    width: 100%;
    > svg {
        top: 10px;
    }
}

.browseSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    width: 100%;

    > button {
        align-items: center;
        background-color: inherit;
        border: 2px dashed $carbon12;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 1em;
        padding-top: 30px;
        width: 100%;
        &:hover {
            border-color: $primary-mid-green-2;
        }

        > p {
            color: $carbon5;
            font-size: 1em;
            font-weight: 500;
            margin-top: 20px;
        }

        > span {
            margin-right: 0.5rem;
        }

        > img {
            width: 20px;
        }

        + span {
            margin-top: 1rem;
        }
    }

    .fileList {
        ul {
            width: 100%;

            li {
                align-items: center;
                border: 2px dashed $carbon10;
                border-radius: 10px;
                display: flex;
                font-size: 0.9em;
                font-weight: 600;
                justify-content: space-between;
                margin-bottom: 0;
                margin-top: 20px;
                padding: 0.4rem 0.6rem;

                &:before {
                    content: none;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                svg {
                    &:first-of-type {
                        vertical-align: middle;
                        stroke: $primary-dark-green;
                    }
                }

                button {
                    background: none;
                    color: $gray-dark;
                    height: 44px;
                    line-height: 0;
                    margin-right: -12px;
                    padding: 10px;
                    width: 44px;
                }

                span {
                    display: inline-block;
                    margin-left: 5px;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: nowrap;
                }

                @media (min-width: 400px) {
                    span {
                        max-width: 300px;
                    }
                }
            }
        }
    }
}
.errorText {
    color: $red-error;
    margin: 0;

    a {
        color: $red-error;
        text-decoration: underline;
    }
}
.uploadingText {
    color: $carbon5;
    margin: 0 0 20px;
    padding: 0;
}

.progressContainer {
    background-color: #d9d9d9;
    border-radius: 50px;
    height: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 100%;
}

.progressBar {
    background-color: $purple-medium;
    border-radius: inherit;
    height: 100%;
    width: var(--progress-width);
}

.percentageText {
    align-self: end;
    margin: 0;
    padding: 0;
}

.uploadSuccessfulSecion {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 10px;
    text-align: center;
    width: 100%;
    z-index: 1;

    svg path {
        stroke: $primary-dark-green;
    }
}
