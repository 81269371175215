@import 'src/css/vars.scss';

.container {
  height: 100vh;
  min-height: 100vh;
  padding: 1rem;

  .account_header {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem 0;
  }

  .gridContainer {
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    .navButton {
      @include normal_border;
      background: $white;
      border-radius: 10px;
      display: grid;
      grid-row-end: 2;
      grid-row-start: 1;
    }

    a {
      display: block;
      padding: 1em 0 1em 1em;
      text-decoration: none;
      svg {
        display: block;
        height: 48px;
        padding-bottom: 1em;
        width: 48px;
        path {
          stroke: $yellow;
        }
      }
    }
  }

  .button_container {
    display: flex;
    justify-content: center;

    margin-top: 2rem;
  }

  .feedback_button {
    justify-content: center;
    width: 25em;
  }
}
