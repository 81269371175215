@import '~src/css/vars.scss';

.jobAgenda {
  padding: 0rem;

  .jobAgendaList {
    .jobAgendaRow {
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75);
      background-color: $carbon4;
      padding: 1rem 1em 2em;
      margin-bottom: 0.25rem;

      .topRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .summary {
        font-size: medium;
        margin-bottom: 1em;
      }

      .tag {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        span:first-of-type {
          display: flex;
          width: 22px;
          height: 22px;

          img {
            width: 100%;
          }
        }

        span:last-of-type {
          padding: 0.1rem 0.4rem;
        }
      }
    }

    .noJobs {
      display: block;
      font-size: 20px;
      margin-top: 20px;
      text-align: center;
      width: 100%;
    }
  }
}
