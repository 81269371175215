@import '~src/css/vars.scss';

.title {
    @include h2;
    margin: 0.5em 0 0.5em;
    padding: 0;
    text-align: center;

    @media (min-width: $ht-lrg) {
        list-style: normal;
        margin-bottom: 0.25em;
    }
}

.description {
    @include h3;
    margin: 0 0 0.5em;
    padding: 0;
    text-align: center;

    @media (min-width: $ht-lrg) {
        line-height: normal;
        margin-bottom: 1.5em;
    }
}

.button {
    width: 100%;
}

.inputAndError {
    .radioContainer {
        display: grid;
        gap: 0.5em;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;

        .radioOption {
            margin-bottom: 0.75em;
            padding-bottom: 0.25em;
            width: 100px;

            input[type='radio'] {
                opacity: 0;
                position: absolute;
                z-index: 100;
            }

            .iconInput:checked + .iconLabel {
                div[role='button'] {
                    background: $primary-mid-green-2;

                    path,
                    path,
                    circle,
                    rect,
                    line,
                    g {
                        stroke: $primary-yellow;
                    }
                }
            }

            .iconLabel {
                display: block;
                height: 100px;
                width: 100px;

                &:hover {
                    cursor: pointer;
                }
            }

            .subCopy {
                display: none;
            }

            .copyLine {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-top: 10px;
                position: relative;

                .copy {
                    display: inline-block;
                    flex: 1 1 48%;
                    font-size: 16px;
                    font-weight: 600;
                }

                .descriptionLabel {
                    background-color: $primary-yellow;
                    border-radius: 20px;
                    display: inline-block;
                    height: 20px;
                    text-align: center;
                    width: 20px;

                    svg {
                        transform: rotate(90deg);
                        width: 15px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .descriptionCheckbox {
                display: none;

                &:checked ~ .descriptionLabel {
                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &:checked ~ .subCopy {
                    background-color: white;
                    border-bottom: 3px solid $primary-mid-green-2;
                    display: block;
                    left: 0;
                    position: absolute;
                    top: 20px;
                    z-index: 1;
                }
            }

            .borderBottom {
                background-color: $primary-mid-green-2;
                display: block;
                height: 3px;
                margin-top: 5px;
                width: 100%;
            }

            input {
                width: 20px;
            }
        }
    }
}

@media (min-width: $ht-lrg) {
    .inputAndError {
        .radioContainer {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}
