.loaderContainer {
  align-items: center;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  min-height: 10em;
  padding: 3.8rem 2.6rem;
}
