@import '~src/css/vars.scss';

.button {
    width: 100%;
}

.select {
    background: $white;
    border: 2px solid $gray-light;
    border-radius: 2em;
    margin-bottom: 14px;
    padding: 0 1em;
}

.title {
    @include h4;
    padding: 28px 1.5em;
    text-align: center;

    @media (min-width: $ht-lrg) {
        max-width: 100%;
    }
}

.textArea {
    border: 2px solid $gray-light;
    margin-bottom: 14px;
}

.validationError {
    color: $red-error;
    margin-bottom: 1em;

    p {
        margin: 0;
        padding-bottom: 10px;
    }

    a {
        color: inherit;
        font-weight: bold;
    }
}

.errorBorder {
    border-color: $red-error;
}
