@import '~src/css/vars.scss';

.header {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  justify-items: start;
  align-items: center;
  width: calc(100% - 40px);

  .postcodeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .postcode {
      line-height: 32px;
      font-size: 24px;
      font-weight: 500;
      margin: 0 0 0 0.3em;
    }
  }

  .jobName {
    margin-right: 1em;
  }
}
