@import 'src/css/vars.scss';

.container {
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .questionContainer {
        width: 100%;
    }

    h1 {
        margin-bottom: 12px;
    }

    h2 {
        margin-bottom: 10px;
    }

    h3 {
        margin-bottom: 5px;
    }

    p {
        padding-bottom: 0;
    }
}

.optionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5em;
    justify-content: center;
    margin: 2em 1em;
}
