@import 'src/css/vars.scss';

.container {
  min-height: 100vh;
  padding: 1rem;

  .backButton {
    svg {
      transform: rotate(-90deg);

      path {
        stroke: $primary-dark-green;
      }
    }
  }
}
