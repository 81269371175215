.container {
    width: 90%;
    margin: 0.5em 1em 0em 1em;
    display: flex;
    flex-direction: column;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 0.5em;
    margin-bottom: 1em;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        margin-left: 0.5em;
    }
}

.removeButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: inherit;
    padding: revert;
}

.partInfo {
    margin: 0.7em 0;
}

.paragraph {
    font-weight: 400;
}
